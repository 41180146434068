import React, {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../data/defaultFormat'

const AwsCards: FC = () => {
  return (
    <section className='aws-cards-container'>
      <div className="container">
        <div className="row py-layout-3">
          <div className="col-md-6 col-sm-12 py-xs-2 mt-2">
            <div>
              <div className="aws__contain-card-info">
                <div className="aws-card aws-lambda">
                  <div className="card-body">
                    <h3 className="card-title text-primary mb-6">
                      <FormattedMessage id="partner_aws.info_card.title1" values={defaultFormatValues} />
                    </h3>
                    <p className="text-primary mt-2">
                      <FormattedMessage id="partner_aws.info_card.description1" values={defaultFormatValues} />
                    </p>
                    <p className="text-primary mt-2">
                      <FormattedMessage id="partner_aws.info_card.description1_2" values={defaultFormatValues} />
                    </p>
                    <p className="text-primary mt-2">
                      <FormattedMessage id="partner_aws.info_card.description1_3" values={defaultFormatValues} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 py-xs-2 mt-2">
            <div className="aws__contain-card-info">
              <div className="aws-card">
                <div className="card-body">
                  <h3 className="card-title text-primary mb-6">
                    <FormattedMessage id="partner_aws.info_card.title2" values={defaultFormatValues} />
                  </h3>
                  <p className="text-primary mt-2">
                    <FormattedMessage id="partner_aws.info_card.description2" values={defaultFormatValues} />
                  </p>
                  <p className="text-primary mt-2">
                    <FormattedMessage id="partner_aws.info_card.description2_2" values={defaultFormatValues} />
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
      </div>
    </section>
  )
}

export default AwsCards