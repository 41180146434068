import React, {FC} from 'react'
import AwsSuccesAguila from './AwsSuccesAguila'
import AwsSuccessLaika from './AwsSuccesLaika'

const AwsSucces: FC = () => {
  return (
    <>
      <AwsSuccesAguila />
      <AwsSuccessLaika />
    </>
  )
}

export default AwsSucces