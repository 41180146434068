import React, {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../data/defaultFormat'

import HeroImg from './assets/images/hero.inline.svg'
import techPartnerImg from './assets/images/partner-tec.svg'
import reviewedImg from './assets/images/reviewed.svg'
import './styles.scss'


const AwsHero: FC = () => {
  return (
    <section className="hero-aws">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12  order-2 order-lg-1 aws-content">
            <div className="p-3">
              <h2 className="aws-title">
                <FormattedMessage id="partner_aws.hero_title" values={defaultFormatValues} />
              </h2>
              <p className="aws-description">
                <FormattedMessage id="partner_aws.hero_description" 
                values={defaultFormatValues} />
              </p>
            </div>
              <div className="aws-partner-network-container">
                <img src={techPartnerImg} alt="" className="aws-partner-card"/>
                <img src={reviewedImg} alt="" className="aws-partner-card"/>
              </div>
          </div>
          <div className="col-md-6 col-sm-12  order-1 order-lg-2 hero-aws-img">
            <HeroImg/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AwsHero
