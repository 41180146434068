import React, {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../data/defaultFormat'
import './styles.scss'
import laika from './assets/images/Laika.png'
import laikaPeople from './assets/images/LaikaPeople.png'
import laikaLogo from './assets/images/laikalogo.svg'

const AwsSuccessLaika: FC = () => {
  return (
    <div className="aws-success-cases-laika">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="aguila-desc-aws">
              <img src={laikaLogo} alt="" />        
              <p className="aguila-desc-aws-desc m-6"><FormattedMessage id= "partner_aws.success.laika.description" /></p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="aws-images">
              <img src={laikaPeople} alt="" className="claro-img"/>
              <img src={laika} alt="" className="claro-img-logo" />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="aguila-challenge">
              <h2 className="aguila-challenge-title"><FormattedMessage id="partner_aws.success.laika.challenge.title" /></h2>
              <p className="aws-list-points">•</p><p className="aws-challenge-claro-desc"><FormattedMessage id="partner_aws.success.laika.challenge.description1" /></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="aguila-about">
            <h2 className="aguila-about-title"><FormattedMessage id="partner_aws.success.laika.about.title" values={defaultFormatValues} /></h2>
            <p className="aguila-about-desc"><FormattedMessage id="partner_aws.success.laika.about.description1" /></p>
            <p className="aguila-about-desc"><FormattedMessage id="partner_aws.success.laika.about.description2" values={defaultFormatValues} /></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AwsSuccessLaika