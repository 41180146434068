import React, {FC} from 'react'
import './styles.scss'

const AwsInfo: FC = () => {
  return (
    <section className="aws-video-container">
      <div className="aws-video-image"></div>
      <div className="container vid-container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="aws-video-card">
              <iframe width="560" height="315" className='aws-video'
              src="https://www.youtube.com/embed/2C8d1XowAUQ&rel=0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AwsInfo
