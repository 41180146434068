import React from 'react'
import Footer from '../components/Footer/Footer'
import AwsHero from '../components/PartnerAws/AwsHero'
import AwsInfo from '../components/PartnerAws/AwsInfo'
import { LocaleTypes } from '../data/locales'
import IndexLayout from '../layouts'
import SEO from '../components/SEO/SEO';
import AwsCards from '../components/PartnerAws/AwsCards'
import AwsSucces from '../components/PartnerAws/AwsSucces'

interface PartnerAwsProps {
  pathContext: {
    localeCode: LocaleTypes;
  }
  location: Location;
}

const partneraws: React.FC<PartnerAwsProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO title="Partner AWS"  />

      <AwsHero />
      <AwsInfo />
      <AwsCards />
      <AwsSucces />
      <Footer theme="primary" />
    </>
  )
  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default partneraws
